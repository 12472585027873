var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "94%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        top: "5vh",
        title: _vm.currtent
          ? _vm.$t("lang_edit_person")
          : _vm.$t("lang_add_persons"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.personData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "180px",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("div", { staticClass: "text_box" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("lang_certificate_information"))),
                ]),
              ]),
              _c("div", { staticClass: "text_box_line" }),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_document_type"),
                            prop: "idType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: _vm.$t(
                                  "lang_please_select_document_type"
                                ),
                              },
                              model: {
                                value: _vm.personData.idType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "idType", $$v)
                                },
                                expression: "personData.idType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_idCard"),
                                  value: "1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_identification_number"),
                            prop: "idCard",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            on: { change: _vm.idCardChange },
                            model: {
                              value: _vm.personData.idCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "idCard", $$v)
                              },
                              expression: "personData.idCard",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text_box" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("lang_base_info")))]),
              ]),
              _c("div", { staticClass: "text_box_line" }),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_full_name"),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            model: {
                              value: _vm.personData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "name", $$v)
                              },
                              expression: "personData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_account"),
                            prop: "account",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: _vm.$t("lang_please_enter"),
                              },
                              on: { change: _vm.accountChange },
                              model: {
                                value: _vm.personData.account,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "account", $$v)
                                },
                                expression: "personData.account",
                              },
                            },
                            [
                              _vm.accountSuffix
                                ? _c("template", { slot: "append" }, [
                                    _vm._v(_vm._s(_vm.accountSuffix) + " "),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("p", { staticClass: "tips" }, [
                            _vm._v(
                              "初始密码为ebYPeApx7%，若密码不慎忘记，请点击右边按钮重置密码！"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c("div", { staticClass: "resetPassword" }, [
                      _c("i", {
                        staticClass: "el-icon-refresh-right",
                        on: { click: _vm.resetPassword },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "出生日期", prop: "birthday" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: _vm.$t("lang_please_select_date"),
                            },
                            model: {
                              value: _vm.personData.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "birthday", $$v)
                              },
                              expression: "personData.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "年龄" } }, [
                        _vm._v(_vm._s(_vm._getAge) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_sex"), prop: "sex" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: _vm.$t("lang_please_select_sex"),
                              },
                              model: {
                                value: _vm.personData.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "sex", $$v)
                                },
                                expression: "personData.sex",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_man"),
                                  value: "1",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_woman"),
                                  value: "2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_health"),
                            prop: "health",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: _vm.$t(
                                  "lang_please_select_health"
                                ),
                              },
                              model: {
                                value: _vm.personData.health,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "health", $$v)
                                },
                                expression: "personData.health",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_sound"),
                                  value: "1",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_good"),
                                  value: "2",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_commonly"),
                                  value: "3",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_have_chronic_diseases"),
                                  value: "4",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "lang_have_physiological_defects"
                                  ),
                                  value: "5",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_disabled"),
                                  value: "6",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_address"),
                            prop: "homeAddress",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            model: {
                              value: _vm.personData.homeAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "homeAddress", $$v)
                              },
                              expression: "personData.homeAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_tel_phone"),
                            prop: "phone",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            model: {
                              value: _vm.personData.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "phone", $$v)
                              },
                              expression: "personData.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_landline_number"),
                            prop: "landline",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            model: {
                              value: _vm.personData.landline,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "landline", $$v)
                              },
                              expression: "personData.landline",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_wechat_number"),
                            prop: "weChatNo",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            model: {
                              value: _vm.personData.weChatNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "weChatNo", $$v)
                              },
                              expression: "personData.weChatNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("lang_email"), prop: "email" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            model: {
                              value: _vm.personData.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "email", $$v)
                              },
                              expression: "personData.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_working_hours"),
                            prop: "workJoinTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: _vm.$t("lang_please_select_date"),
                            },
                            model: {
                              value: _vm.personData.workJoinTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "workJoinTime", $$v)
                              },
                              expression: "personData.workJoinTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_marital_status"),
                            prop: "maritalStatus",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: _vm.$t(
                                  "lang_please_select_marital_status"
                                ),
                              },
                              model: {
                                value: _vm.personData.maritalStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "maritalStatus", $$v)
                                },
                                expression: "personData.maritalStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_unmarried"),
                                  value: "1",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_married"),
                                  value: "2",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_divorce"),
                                  value: "3",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_widowhood"),
                                  value: "4",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_other"),
                                  value: "5",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_country"),
                            prop: "country",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("lang_please_enter") },
                            model: {
                              value: _vm.personData.country,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "country", $$v)
                              },
                              expression: "personData.country",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_nation"),
                            prop: "country",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择民族",
                              },
                              model: {
                                value: _vm.personData.nation,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "nation", $$v)
                                },
                                expression: "personData.nation",
                              },
                            },
                            _vm._l(_vm.nationList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_political_outlook") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择政治面貌",
                              },
                              model: {
                                value: _vm.personData.politicalStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.personData,
                                    "politicalStatus",
                                    $$v
                                  )
                                },
                                expression: "personData.politicalStatus",
                              },
                            },
                            _vm._l(_vm.politicalList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "年假", prop: "yearDays" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.personData.yearDays,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "yearDays", $$v)
                              },
                              expression: "personData.yearDays",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("div", { staticClass: "text_box" }, [
                  _c("span", [_vm._v("学历信息")]),
                ]),
                _c("div", { staticClass: "text_box_line" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_education") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择全日制学历",
                              },
                              model: {
                                value: _vm.personData.fullEdu,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "fullEdu", $$v)
                                },
                                expression: "personData.fullEdu",
                              },
                            },
                            _vm._l(
                              _vm.dayEducationList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_academic_degree"),
                            prop: "country",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择全日制学位",
                              },
                              model: {
                                value: _vm.personData.fullDeg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "fullDeg", $$v)
                                },
                                expression: "personData.fullDeg",
                              },
                            },
                            _vm._l(_vm.dayDegreeList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_job_education") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择在职制学历",
                              },
                              model: {
                                value: _vm.personData.jobEdu,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "jobEdu", $$v)
                                },
                                expression: "personData.jobEdu",
                              },
                            },
                            _vm._l(
                              _vm.dayEducationList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_job_degree"),
                            prop: "country",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择在职制学位",
                              },
                              model: {
                                value: _vm.personData.jobDeg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "jobDeg", $$v)
                                },
                                expression: "personData.jobDeg",
                              },
                            },
                            _vm._l(_vm.dayDegreeList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("div", { staticClass: "text_box" }, [
                  _c("span", [_vm._v("户口信息")]),
                ]),
                _c("div", { staticClass: "text_box_line" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "lang_ household_registration_nature"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择户口性质",
                              },
                              model: {
                                value: _vm.personData.regType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "regType", $$v)
                                },
                                expression: "personData.regType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "农村户口", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "非农村户口", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "籍贯" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.personData.nativePlace,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "nativePlace", $$v)
                              },
                              expression: "personData.nativePlace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_user_type"),
                            prop: "userType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: _vm.$t(
                                  "lang_please_select_user_type"
                                ),
                              },
                              on: { change: _vm.changeValue },
                              model: {
                                value: _vm.personData.userType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "userType", $$v)
                                },
                                expression: "personData.userType",
                              },
                            },
                            _vm._l(
                              _vm.userTypeList,
                              function (userItem, userIndex) {
                                return _c("el-option", {
                                  key: userIndex,
                                  attrs: {
                                    label: userItem.name,
                                    value: userItem.code,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("lang_registered_residence") },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.personData.domicile,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "domicile", $$v)
                              },
                              expression: "personData.domicile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("div", { staticClass: "text_box" }, [
                  _c("span", [_vm._v("党组织信息")]),
                ]),
                _c("div", { staticClass: "text_box_line" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_join_party_time") } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.personData.partyJoinTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "partyJoinTime", $$v)
                              },
                              expression: "personData.partyJoinTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_party_age") } },
                        [_vm._v(" " + _vm._s(_vm._getPartyAge) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所在党支部" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.personData.partyBranch,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "partyBranch", $$v)
                              },
                              expression: "personData.partyBranch",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_party_member_relations"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.personData.partyRelation,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "partyRelation", $$v)
                              },
                              expression: "personData.partyRelation",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "转为正式党员日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.personData.regularDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "regularDate", $$v)
                              },
                              expression: "personData.regularDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "党籍状态" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.personData.partyStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "partyStatus", $$v)
                              },
                              expression: "personData.partyStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否为失联党员" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.personData.linkFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "linkFlag", $$v)
                                },
                                expression: "personData.linkFlag",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "否", value: false },
                              }),
                              _c("el-option", {
                                attrs: { label: "是", value: true },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "失去联系日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: !_vm.personData.linkFlag,
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.personData.loseLinkDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "loseLinkDate", $$v)
                              },
                              expression: "personData.loseLinkDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否为流动党员" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.personData.flowFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personData, "flowFlag", $$v)
                                },
                                expression: "personData.flowFlag",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: true },
                              }),
                              _c("el-option", {
                                attrs: { label: "否", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "外出流向" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: !_vm.personData.flowFlag,
                              type: "text",
                            },
                            model: {
                              value: _vm.personData.outFlowDir,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "outFlowDir", $$v)
                              },
                              expression: "personData.outFlowDir",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "荣誉情况" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 2 },
                            model: {
                              value: _vm.personData.honors,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "honors", $$v)
                              },
                              expression: "personData.honors",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("div", { staticClass: "text_box" }, [
                  _c("span", [_vm._v("其他信息")]),
                ]),
                _c("div", { staticClass: "text_box_line" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            id: "icon",
                            label: _vm.$t("lang_signature_stamp"),
                            prop: "sign",
                          },
                        },
                        [
                          _c("glb-img-upload", {
                            attrs: {
                              "upload-url": _vm.uploadUrl,
                              "pub-preview-url": _vm.pubPreviewUrl,
                              maxUploadSize: 1,
                            },
                            model: {
                              value: _vm.personData.sign,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "sign", $$v)
                              },
                              expression: "personData.sign",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            id: "icon",
                            label: _vm.$t("lang_avatar"),
                            prop: "avatar",
                          },
                        },
                        [
                          _c("glb-img-upload", {
                            attrs: {
                              "upload-url": _vm.uploadUrl,
                              "pub-preview-url": _vm.pubPreviewUrl,
                              maxUploadSize: 1,
                            },
                            model: {
                              value: _vm.personData.avatar,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "avatar", $$v)
                              },
                              expression: "personData.avatar",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remarks" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "备注内容",
                            },
                            model: {
                              value: _vm.personData.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "remarks", $$v)
                              },
                              expression: "personData.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_sort"), prop: "sort" } },
                        [
                          _c("el-input-number", {
                            model: {
                              value: _vm.personData.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.personData, "sort", $$v)
                              },
                              expression: "personData.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("div", { staticClass: "text_box" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("lang_main_company_information"))),
                  ]),
                ]),
                _c("div", { staticClass: "text_box_line" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_main_company_name"),
                        prop: "maritalStatus",
                      },
                    },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.companyList,
                          "append-to-body": "",
                          "z-index": 2999,
                          "default-expand-level": 1,
                          normalizer: _vm.menuTreeNormalizer,
                          placeholder: _vm.$t("lang_please_select"),
                        },
                        model: {
                          value: _vm.personData.defaultCompanyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.personData, "defaultCompanyId", $$v)
                          },
                          expression: "personData.defaultCompanyId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("div", { staticClass: "text_box" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("lang_contract_information"))),
                  ]),
                ]),
                _c("div", { staticClass: "text_box_line" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "入职日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.laborContract.employmentDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.laborContract,
                                  "employmentDate",
                                  $$v
                                )
                              },
                              expression: "laborContract.employmentDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "lang_actual_employment_confirmation_date"
                            ),
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value:
                                _vm.laborContract.factEmploymentConfirmDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.laborContract,
                                  "factEmploymentConfirmDate",
                                  $$v
                                )
                              },
                              expression:
                                "laborContract.factEmploymentConfirmDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("lang_shouild_change_date") },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.laborContract.employmentConfirmDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.laborContract,
                                  "employmentConfirmDate",
                                  $$v
                                )
                              },
                              expression: "laborContract.employmentConfirmDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_ contract_period") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择合同期限" },
                              model: {
                                value: _vm.laborContract.period,
                                callback: function ($$v) {
                                  _vm.$set(_vm.laborContract, "period", $$v)
                                },
                                expression: "laborContract.period",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "无固定期限", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "固定期限", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_contract_expiration_date"),
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled:
                                !_vm.personData.period ||
                                _vm.personData.period == "2",
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.laborContract.invalidDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.laborContract, "invalidDate", $$v)
                              },
                              expression: "laborContract.invalidDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同到期提醒时间" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.laborContract.expReminder,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.laborContract,
                                    "expReminder",
                                    $$v
                                  )
                                },
                                expression: "laborContract.expReminder",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    right: "-5px",
                                    width: "120px",
                                  },
                                  attrs: {
                                    slot: "suffix",
                                    placeholder: "请选择",
                                  },
                                  slot: "suffix",
                                  model: {
                                    value: _vm.laborContract.expUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.laborContract,
                                        "expUnit",
                                        $$v
                                      )
                                    },
                                    expression: "laborContract.expUnit",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "单位:日", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "单位:周", value: "2" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "单位:月", value: "3" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "单位:年", value: "4" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用工形式" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择用工形式" },
                              model: {
                                value: _vm.laborContract.useWorkType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.laborContract,
                                    "useWorkType",
                                    $$v
                                  )
                                },
                                expression: "laborContract.useWorkType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全日制", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "非全日制", value: "2" },
                              }),
                              _c("el-option", {
                                attrs: { label: "劳务派遣", value: "3" },
                              }),
                              _c("el-option", {
                                attrs: { label: "劳务人员", value: "4" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "试用期到期提醒时间" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.laborContract.trialReminder,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.laborContract,
                                    "trialReminder",
                                    $$v
                                  )
                                },
                                expression: "laborContract.trialReminder",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    right: "-5px",
                                    width: "120px",
                                  },
                                  attrs: {
                                    slot: "suffix",
                                    placeholder: "请选择",
                                  },
                                  slot: "suffix",
                                  model: {
                                    value: _vm.laborContract.trialUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.laborContract,
                                        "trialUnit",
                                        $$v
                                      )
                                    },
                                    expression: "laborContract.trialUnit",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "单位:日", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "单位:周", value: "2" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "单位:月", value: "3" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "单位:年", value: "4" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("div", { staticClass: "text_box" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("lang_dept_info")))]),
                ]),
                _c("div", { staticClass: "text_box_line" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.deptAddClick()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_new")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.personData.takeOffices,
                        border: "",
                        "empty-text": _vm.$t("lang_no_data"),
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_department"),
                          align: "center",
                          prop: "deptId",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDeptName(
                                        _vm.deptListData,
                                        scope.row.deptId
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_duty"),
                          align: "center",
                          prop: "dutyId",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDeptName(
                                        _vm.dutyListData,
                                        scope.row.dutyId
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_is_it_the_competent_department"),
                          align: "center",
                          prop: "defaultAble",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.defaultAble
                                        ? _vm.$t("lang_yes")
                                        : _vm.$t("lang_no")
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_tenure"),
                          align: "center",
                          prop: "takeOfficeTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_employment_status"),
                          align: "center",
                          prop: "status",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == "1"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v(_vm._s(_vm.$t("lang_in_office")))]
                                    )
                                  : _vm._e(),
                                scope.row.status == "2"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("lang_leave_office"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.status == "3"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_reason_for_employment"),
                          align: "center",
                          prop: "takeOfficeReason",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_time_of_resignation"),
                          align: "center",
                          prop: "leaveOfficeTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_reason_for_resignation"),
                          align: "center",
                          prop: "leaveOfficeReason",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_operation"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      color: "#007aff",
                                      "margin-left": "10px",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deptAddClick(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("lang_edit")))]
                                ),
                                !scope.row.id
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          color: "#e03e2d",
                                          "margin-left": "10px",
                                        },
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeDept(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("div", { staticClass: "text_box" }, [
                  _c("span", [_vm._v("附件信息")]),
                ]),
                _c("div", { staticClass: "text_box_line" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.personData.takeAnnex,
                        border: "",
                        "empty-text": _vm.$t("lang_no_data"),
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "简历", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.resume,
                                    callback: function ($$v) {
                                      _vm.$set(row, "resume", $$v)
                                    },
                                    expression: "row.resume",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "offer letter签字版", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.offerLetter,
                                    callback: function ($$v) {
                                      _vm.$set(row, "offerLetter", $$v)
                                    },
                                    expression: "row.offerLetter",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "原单位离职证明", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.leavingCert,
                                    callback: function ($$v) {
                                      _vm.$set(row, "leavingCert", $$v)
                                    },
                                    expression: "row.leavingCert",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "劳动合同", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.laborContract,
                                    callback: function ($$v) {
                                      _vm.$set(row, "laborContract", $$v)
                                    },
                                    expression: "row.laborContract",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "身份证正面",
                          align: "center",
                          prop: "takeOfficeReason",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.idCardFront,
                                    callback: function ($$v) {
                                      _vm.$set(row, "idCardFront", $$v)
                                    },
                                    expression: "row.idCardFront",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "身份证反面",
                          align: "center",
                          prop: "takeOfficeReason",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.idCardBack,
                                    callback: function ($$v) {
                                      _vm.$set(row, "idCardBack", $$v)
                                    },
                                    expression: "row.idCardBack",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "毕业证", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.diploma,
                                    callback: function ($$v) {
                                      _vm.$set(row, "diploma", $$v)
                                    },
                                    expression: "row.diploma",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "学位证", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.degree,
                                    callback: function ($$v) {
                                      _vm.$set(row, "degree", $$v)
                                    },
                                    expression: "row.degree",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "银行卡", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.bankCard,
                                    callback: function ($$v) {
                                      _vm.$set(row, "bankCard", $$v)
                                    },
                                    expression: "row.bankCard",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "1寸照片（电子）", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.oneInchPhoto,
                                    callback: function ($$v) {
                                      _vm.$set(row, "oneInchPhoto", $$v)
                                    },
                                    expression: "row.oneInchPhoto",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "体检报告", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("ele-upload", {
                                  staticClass: "upload-demo",
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: true, limit: 6 },
                                  model: {
                                    value: row.medicalReport,
                                    callback: function ($$v) {
                                      _vm.$set(row, "medicalReport", $$v)
                                    },
                                    expression: "row.medicalReport",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_operation"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      color: "#007aff",
                                      "margin-left": "10px",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileAddClick(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("lang_edit")))]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      color: "#e03e2d",
                                      "margin-left": "10px",
                                    },
                                    attrs: { slot: "reference", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFile()
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("清空")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
      _vm.isShowAddEdit
        ? _c("dept-add-edit", {
            attrs: {
              visible: _vm.isShowAddEdit,
              deptRow: _vm.deptRow,
              deptListData: _vm.deptListData,
              dutyListData: _vm.dutyListData,
              takePostListData: _vm.takePostListData,
              frontDisplayData: _vm.frontDisplayData,
              companyId: _vm.companyId,
              groupIds: _vm.groupIds,
              proviceList: _vm.proviceList,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isShowAddEdit = $event
              },
              done: _vm.deptClose,
            },
          })
        : _vm._e(),
      _vm.isShowAddFileEdit
        ? _c("file-add-edit", {
            attrs: {
              visible: _vm.isShowAddFileEdit,
              fileRow: _vm.fileRow,
              fileListData: _vm.fileListData,
              companyId: _vm.companyId,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isShowAddFileEdit = $event
              },
              upDone: _vm.upFileClose,
              done: _vm.fileClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }